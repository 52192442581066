export default (data = {}) => {
    return {
        algorithm: null,
        application_response_base64_bytes: null,
        attached_document_base64_bytes: null,
        class: null,
        errors_messages: null,
        expedition_date: null,
        is_restored: null,
        is_valid: null,
        issue_date: null,
        mail_sending_message: null,
        number: null,
        payload: null,
        pdf_base64_bytes: null,
        pdf_download_link: null,
        qr_code: null,
        qr_data: null,
        qr_link: null,
        signature: null,
        status_code: null,
        status_description: null,
        status_message: null,
        type_environment_id: null,
        uuid: null,
        xml_base64_bytes: null,
        xml_name: null,
        zip_base64_bytes: null,
        zip_key: null,
        zip_name: null,
        ...data
    }
}