export default (data = {}) => {
    return {
        docType: [
            {
              label: "Comprobante de Ingreso",
              value: "CIN",
              to: "Recibido de",
              concept: "Por concepto de:",
              paymode: "Forma de pago",
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por pagar",
                },
              ],
            },
            {
              label: "Comprobante de Egreso",
              value: "CEG",
              to: "Pagado a",
              concept: "Por concepto de:",
              paymode: "Pagado a",
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por Cobrar",
                },
              ],
            },
            {
              label: "ORDEN DE COMPRA CLIENTE",
              value: "OR",
              to: "Cliente",
              concept: "Productos / Servicios",
              paymode: "Forma de pago",
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por pagar",
                },
              ],
            },
            {
              label: "REMISION CLIENTE",
              value: "REC",
              to: "Destino",
              concept: "Productos / Servicios",
              paymode: "Forma de pago",
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por pagar",
                },
              ],
            },
            {
              label: "ORDEN DE COMPRA - PROVEEDOR",
              value: "ORP",
              to: "Proveedor",
              concept: "Producto / Servicio",
              paymode: "Forma de pago",
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por pagar",
                },
              ],
            },
            { label: "GASTO", value: "GST" },
            {
              label: "Factura Electrónica",
              value: "FE",
              to: "Cliente",
              concept: "Producto / Servicio",
              paymode: "Forma de pago",
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por pagar",
                },
              ],
            },
          ],
        ...data
    }
}
