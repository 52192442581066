<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-form v-model="valid" ref="itemForm" @submit="item_submit">
        <v-card tile height="100%">
          <v-card-title class="pa-0">
            <v-app-bar elevation="2">
              <v-tabs show-arrows v-model="tab">
                <v-tab key="product">
                  <v-icon left> mdi-card-text-outline </v-icon>
                </v-tab>
                <v-tab key="photo">
                  <v-icon> mdi-camera-plus-outline </v-icon>
                </v-tab>
                <v-tab key="setting">
                  <v-icon> mdi-cog-outline </v-icon>
                </v-tab>
              </v-tabs>
              <v-spacer></v-spacer>
              <v-btn icon fab color="success" @click="close()">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-app-bar>
          </v-card-title>
          <v-card-text class="pa-2">
            <v-tabs-items v-model="tab">
              <v-tab-item key="producto">
                <v-row class="pa-2">
                  <v-col cols="12" md="6">
                    <v-combobox
                      v-model="item.category"
                      id="category"
                      hide-details="auto"
                      label="Categoría"
                      :items="categories"
                      :rules="f_required"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-combobox
                      v-model="item.subcategory"
                      id="subcategory"
                      hide-details="auto"
                      label="Sub-categoria"
                      :items="subcategories"
                      :rules="f_required"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="item.product_name"
                      id="product_name"
                      hide-details="auto"
                      label="Nombre"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                    <v-col cols="6" md="4">
                    <v-text-field
                      v-model="item.tax"
                      label="IVA"
                      class="centered-input"
                      hide-details="auto"
                      @keypress="onlyNumber"
                      append-icon="mdi-percent"
                      outlined
                      required
                      dense
                    />
                    
                  </v-col>
                  <v-col cols="3" md="4">
                    <v-text-field
                      v-model="item.cost"
                      label="Valor"
                      class="centered-input"
                      hide-details="auto"
                      @keypress="onlyNumber"
                      @change="PriceCalculator"
                      prepend-inner-icon="mdi-currency-usd"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" class="vueEditorCSS">
                    <vue-editor
                      v-model="item.description"
                      :editorToolbar="customToolbar"
                    ></vue-editor>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="photo" align="center" justify="center">
                <v-img
                  contain
                  aspect-ratio="1"
                  class="grey lighten-5"
                  :src="productPhoto"
                  max-height="250"
                >
                </v-img>
                <v-file-input
                  v-model="chosenPhoto"
                  counter
                  @input="PhotoToBase64"
                  label="Foto"
                  placeholder="Seleccione una imagen"
                  append-outer-icon="mdi-upload"
                  @click:append-outer="PhotoToBase64"
                  outlined
                  :show-size="1000"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" label small>
                      {{ text }}
                    </v-chip>

                    <span
                      v-else-if="index === 2"
                      class="text-overline grey--text text--darken-3 mx-2"
                    >
                      +{{ chosenFile.length - 2 }} File(s)
                    </span>
                  </template>
                </v-file-input>
              </v-tab-item>
              <v-tab-item key="setting">
                <v-row class="pa-2">
                  <v-col cols="6" md="4">
                    <v-text-field
                      class="centered-input"
                      hide-details="auto"
                      :rules="f_required"
                      v-model="item.size"
                      @keypress="onlyNumber"
                      label="Tamaño"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-select
                      v-model="item.uom"
                      hide-details="auto"
                      :rules="f_required"
                      item-text="text"
                      item-value="value"
                      :items="uom_lst"
                      label="UOM"
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-text-field
                      v-model="item.pack"
                      label="Pack"
                      class="centered-input"
                      hide-details="auto"
                      @keypress="onlyNumber"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-combobox
                      v-model="item.brand"
                      id="brand"
                      hide-details="auto"
                      label="Marca"
                      :items="brands"
                      :rules="f_required"
                      outlined
                      required
                      dense
                      @keyup="returnCap"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="item.reference"
                      id="reference"
                      hide-details="auto"
                      label="Referencia"
                      :rules="f_required"
                      outlined
                      required
                      dense
                      @keyup="returnCap"
                    />
                  </v-col>

                  <v-col cols="6" md="6">
                    <v-select
                      v-model="item.active"
                      hide-details="auto"
                      :rules="f_required"
                      item-text="text"
                      item-value="value"
                      :items="active_lst"
                      label="Status"
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="item.sku"
                      hide-details="auto"
                      label="SKU/EAN/CODIGO"
                      append-icon="mdi-barcode-scan"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="delete_itm(item)" v-if="!newItem">
              <span>Borrar</span>
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="!valid" type="submit">
              <span>Guardar</span>
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      <!-- </v-dialog> -->
    </v-dialog>
    <v-dialog persistent max-width="290" v-model="dialog_confirm">
      <v-card>
        <v-card-title> Confirmar </v-card-title>
        <v-card-text> Desea eliminar el registro? </v-card-text>
        <v-card-actions>
          <v-btn
            large
            text
            color="red"
            type="button"
            @click="dialog_confirm = !dialog_confirm"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn large text color="success" type="button" @click="delete_item">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alerts" hide-overlay persistent>
      <v-card>
        <v-alert border="left" color="orange" prominent text type="warning">
          <v-row align="center">
            <v-col class="grow"> Formato de archivo o tamaño no valido. </v-col>
            <v-col class="shrink">
              <v-btn fab x-small @click="alerts = false">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createItem from "../utils/createItem.js";
import { VueEditor } from "vue2-editor";
export default {
  name: "ProductForm",
  components: { VueEditor },
  data() {
    return {
      alerts: false,
      loading_status: false,
      tab: null,
      dialog_confirm: false,
      compressed_image: null,
      reg_delete: null,
      valid: false,
      customToolbar: [
        // [{ font: [] }],
        // [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        //  [{ header: 1 }, { header: 2 }],
        //[{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        //[{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
      active_lst: [
        { value: "1", text: "Activo" },
        { value: "0", text: "InActivo" },
      ],
      uom_lst: [
        { value: "UN", text: "Unidad" },
        { value: "GR", text: "Gramo" },
        { value: "KG", text: "Kilogramo" },
        { value: "LB", text: "Libra" },
        { value: "LT", text: "Litro" },
        { value: "ML", text: "Mililitro" },
        { value: "GL", text: "Galon" },
        { value: "OZ", text: "Onza" },
        { value: "Mes", text: "Mes" },
        { value: "Año", text: "Año" },
        { value: "Día", text: "Día" },
      ],
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length === 4 || "EL código debe tener 4 caracteres.",
      ],
      productPhoto: null,
      chosenPhoto: null,
      item: createItem(),
    };
  },
  props: {
    table: {
      type: String,
      required: true,
    },
    store: {
      type: String,
      default: "",
      required: true,
    },
    categories: {
      type: Array,
      required: false,
    },
    subcategories: {
      type: Array,
      required: false,
    },
    brands: {
      type: Array,
      required: false,
    },
    suppliers: {
      type: String,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    newItem: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {},
  methods: {
    PriceCalculator() {
      //this.item.price = parseFloat((parseFloat(this.item.cost) / (100-parseFloat(this.item.utility))*100).toFixed(2));
      //this.item.price = parseFloat(this.item.cost) * ((100+parseFloat(this.item.utility))/100).toFixed(2);
    },
    PhotoToBase64() {
      if (!this.chosenPhoto) {
        this.data = "No File Chosen";
      }
      this.getBase64(this.chosenPhoto).then((data) => {
        this.compressed_image = data;
        this.productPhoto = data;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    returnCap(event) {
      this.item[event.target.id] = event.target.value.toUpperCase();
    },
    onlyNumber($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    getImagePhoto: function (account, store, src) {
      var url = "https://mayorista.app/photos/" + account + "/000000/" + src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    item_submit(e) {
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        var normalizer = 1;
        switch (this.item.uom) {
          case "LB":
            normalizer = 453;
            this.item.normalized_uom = "GR";
            break;
          case "LT":
            normalizer = 1000;
            this.item.normalized_uom = "ML";
            break;
          case "KG":
            normalizer = 1000;
            this.item.normalized_uom = "GR";
            break;
          case "OZ":
            normalizer = 28.34;
            this.item.normalized_uom = "GR";
            break;
          case "GL":
            normalizer = 3785.4;
            this.item.normalized_uom = "ML";
            break;
          case "ML":
            normalizer = 1;
            this.item.normalized_uom = "ML";
            break;
          case "UN":
            normalizer = 1;
            this.item.normalized_uom = "UN";
            break;
          case "GR":
            normalizer = 1;
            this.item.normalized_uom = "GR";
            break;
          case "Mes":
            normalizer = 1;
            this.item.normalized_uom = "Mes";
            break;
          case "Año":
            normalizer = 1;
            this.item.normalized_uom = "Año";
            break;
          case "Día":
            normalizer = 1;
            this.item.normalized_uom = "Día";
            break;
          default:
            normalizer = 1;
            this.item.normalized_uom = "UN";
        }
        this.item.normalized_size = parseFloat(this.item.size) * normalizer;
        this.item.price = Math.round(
          (parseFloat(this.item.cost) / (100 - parseFloat(this.item.utility))) * 100
        );

        this.item.cost_uom =
          parseFloat(this.item.cost) / parseFloat(this.item.normalized_size);
        this.item.price_uom =
          parseFloat(this.item.price) / parseFloat(this.item.normalized_size);
        // this.item.store = this.store;
        var qry = {
          account: this.$store.state.profile.account,
          table: this.table,
          data: [this.item],
        };
        if (this.compressed_image) {
          qry.photo = this.compressed_image;
        }

        this.item.account = this.$store.state.profile.account;
        this.item.store = "000000";
        this.item.supplier = "000000";
        webserver("put_table", qry, (data) => {
          this.compressed_image = null;
          this.loading_status = false;
          this.$emit("refresh", data);
        });
      }
    },
    delete_itm(data) {
      this.reg_delete = data.code;
      this.dialog_confirm = true;
    },
    delete_item() {
      this.loading_status = true;
      var qry = {
        table: this.table,
        filters: {
          account: this.item.account,
          store: this.item.store,
          code: this.item.code,
        },
      };
      webserver("delete_record", qry, () => {
        this.dialog_confirm = false;
        this.loading_status = false;
        this.$emit("refresh", true);
      });
    },
    close() {
      this.$emit("close", true);
    },
  },
  watch: {
    dialog: function () {
      // if (!e.picture) {
      //   this.compressed_image = null;
      // }
      ////console.log(e);
    },
    item: function (e) {
      // this.item.supplier = this.suppliers.find(
      //   (prv) => prv.code.toUpperCase() === this.item.supplier.toUpperCase()
      // );
      this.compressed_image = null;
      if (e.picture) {
        this.productPhoto = this.getImagePhoto(e.account, "000000", e.picture);
      } else {
        this.productPhoto = null;
      }
    },
  },
};
</script>

<style>
.btn-upload {
  top: 100px;
}

.bar-top {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 50;
}

.base-image-input {
  display: block;
  width: 100%;
  height: 250px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}
</style>
